<template>
  <div
    :class="[
      $style.formRow,
      {
        [$style.formRowStretch]: stretch,
      },
      $style[rowSpace],
      {
        [$style.formRowFocused]: isFocused,
        [$style.formRowFocusedOffset]: (isFocused || modelValueData) && (modelValue.label && showFormLabelElement && !modelValue.outerLabel),
      }
    ]"
  >
    <slot>
      <div :class="[$style.formRowControl]">
        <template v-if="showFormLabelElement">
          <aw-form-label
            v-if="!modelValue.outerLabel && modelValue.label"
            v-model="modelValueData"
            :is-focused="isFocused || isClicked"
          >
            <template #before>
              <slot name="label-before" />
            </template>
            <template #after>
              <slot name="label-after" />
            </template>
          </aw-form-label>
          <label
            v-else-if="modelValue.outerLabel && labelValue"
            :id="labelUniqueId"
            :class="[$style[modelValue.inputSize ? modelValue.inputSize : 'lg']]"
            :for="(modelValue.labelAttrs && modelValue.labelAttrs.for) || widgetUniqueId"
            v-html="labelValue"
          />
        </template>
        <aw-form-widget
          ref="formWidget"
          :key="`${modelValue.name}-${modelValue.type}`"
          v-model="modelValueData"
          :is-focused="isFocused"
          :show-form-row-label="showFormLabelElement"
          @focus="onWidgetFocus"
          @blur="onWidgetBlur"
          @typeahead-enter="event => $emit('typeahead-enter', event)"
          @file-manual-upload="event => $emit('file-manual-upload', event)"
          @click="onWidgetClick"
        >
          <template #prefix>
            <slot name="prefix" />
          </template>
          <template #postfix>
            <slot name="postfix" />
          </template>
          <template #before>
            <slot name="widget-before" />
          </template>
          <template #after>
            <div
              v-if="modelValue.labelAfter"
              :class="[$style.labelAfter, {[$style.labelAfterCheckbox]: modelValue.type === 'checkbox' }]"
              v-text="modelValue.labelAfter"
            />
            <slot name="widget-after" />
          </template>
        </aw-form-widget>
      </div>
      <template v-if="!modelValue.formAttrs?.hideErrors">
        <aw-form-error-notification v-if="modelValue.isBoxError" v-model="modelValueData" />
        <aw-form-error v-else v-model="modelValueData" :input-size="modelValue.inputSize" />
      </template>
      <aw-form-success v-model="modelValueData" :input-size="modelValue.inputSize" />
    </slot>
  </div>
</template>

<script>
  import { mapState } from 'pinia';
  import AwFormWidget from './AwFormWidget';
  import AwFormLabel from './AwFormLabel';
  import AwFormError from './AwFormError';
  import AwFormErrorNotification from './AwFormErrorNotification';
  import AwFormSuccess from './AwFormSuccess';
  import formMixin from '~~/common/mixins/formMixin';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';

  export default {
    name: 'AwFormRowVersion2',
    components: {
      AwFormSuccess,
      AwFormError,
      AwFormErrorNotification,
      AwFormLabel,
      AwFormWidget,
    },
    mixins: [formMixin],
    props: {
      rowSpace: {
        type: String,
        default: 'md',
        validator: val => ['none', 'md'].includes(val),
      },
      stretch: {
        type: Boolean,
        default: false,
      },
      showFormRowLabel: {
        type: [Boolean, String],
        default: 'mobile-max',
      },
    },
    emits: ['typeahead-enter', 'file-manual-upload'],
    data () {
      return {
        isFocused: false,
        isClicked: false,
      };
    },
    computed: {
      ...mapState(useUserInterfaceStore, {
        screenRange: state => state.mediaQueries,
      }),
      showFormLabelElement () {
        return this.showFormRowLabel === true || this.screenRange[this.showFormRowLabel];
      },
    },
    watch: {},
    created () {
      const formComponent = this.findComponent('AwForm');
      if (!formComponent) {
        throw new Error('Can\'t find AwForm in parents component');
      }

      this.modelValueData = {
        ...this.modelValue,
        validation: formComponent.modelValue,
      };
    },
    methods: {
      onWidgetFocus () {
        this.isFocused = true;
      },
      onWidgetClick () {
        this.isClicked = true;
      },
      onWidgetBlur () {
        this.isFocused = false;
        this.isClicked = false;
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.formRow {
  font-family: $secondary-font;

  &Stretch {
    width: 100%;
  }

  &Focused {
    // stylelint-disable length-zero-no-unit
    --input-content-offset: 0px;

    &Offset {
      --input-content-offset: 8px;
    }
  }

  .labelAfter {
    font-size: 14px;
    font-weight: 500;
    font-style: italic;
    line-height: 20px;
    margin-top: 4px;
    margin-left: 16px;
    color: $color-text-secondary;

    &Checkbox {
      margin: 16px 0 0;
    }
  }

  &.md {
    margin-bottom: 20px;
  }

  &Control {
    position: relative;
  }

  label {
    &.xs {
      font-size: $font-size-lg;
    }

    &.md {
      font-size: $font-size-md;
    }

    &.lg {
      font-size: $font-size-xs;
    }
  }
}
</style>
