<template>
  <div v-if="showPaymentForm">
    <aw-notification-item
      :notification="{
        type: 'info',
        iconName: 'education-16',
        manualClose: false,
        text: {
          subtitle: $awt('aw.basket.order_success.payment_failed_info_box')
        }
      }"
      :inlined-content="true"
      :close="() => ({})"
      index="0"
      style="margin-bottom: 20px"
    />
    <aw-form v-model="v$" @on-submit="onSubmitForm(orderIdent)">
      <div v-if="showCardInfo" :class="$style.formFields">
        <aw-form-row-version2 v-model="onlinePayment.card" row-space="none" :class="$style.card" />
        <template v-if="!onlinePayment.card.model.value">
          <aw-form-row-version2
            v-model="onlinePayment.cardDescription"
            show-form-row-label
            row-space="none"
            :class="$style.description"
          />
          <aw-form-row-version2
            v-model="onlinePayment.lastDigits"
            show-form-row-label
            row-space="none"
            :class="$style.lastDigits"
          />
        </template>
      </div>
      <aw-form-row-version2 v-model="onlinePayment.keep" />
      <div :class="$style.buttons">
        <div :class="$style.submitButton">
          <aw-form-button v-model="v$" styling="small-primary-dark">
            {{ $awt('aw.basket.order_success.new_transaction') }}
          </aw-form-button>
        </div>
        <div v-if="paymentChangeable" :class="$style.changeToCodButton">
          <lv-button
            type="button"
            styling="small-secondary-dark"
            @click="changeToCOD(orderIdent)"
          >
            {{ $awt('aw.basket.order_success.change_to_cod') }}
          </lv-button>
        </div>
      </div>
      <button v-show="false" ref="right" type="submit" />
    </aw-form>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import { useVuelidate } from '@vuelidate/core';
  import { maxLength, minLength, requiredIf } from '@vuelidate/validators';
  import { useRuntimeConfig, useNuxtApp } from 'nuxt/app';
  import { LvButton } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { setErrorNotification } from '~~/common/utils/helper';
  import { BrowserStats } from '~~/common/utils/BrowserStats.js';
  import AwForm from '~~/common/components/Form/AwForm';
  import AwFormRowVersion2 from '~~/common/components/Form/AwFormRowVersion2';
  import AwFormButton from '~~/common/components/Form/AwFormButton';

  import { useNotificationStore } from '~~/common/stores/notification';
  import { useOrderStore } from '~~/common/stores/order';
  import { CREDIT_CARD_MAX_COUNT_MAGIC_NUMBER } from '~~/shop/config/CheckoutCardConfig.js';

  export default {
    name: 'AwFailedOrderContentVersion2',
    components: {
      AwForm,
      AwFormRowVersion2,
      AwFormButton,
      LvButton,
      AwNotificationItem: defineAsyncComponent(() => import('~~/common/components/Common/AwNotificationItem')),
    },
    props: {
      orderIdent: {
        type: String,
        required: true,
      },
      fullWidth: {
        type: Boolean,
        default: false,
      },
      showCardInfo: {
        type: Boolean,
        required: true,
      },
    },
    emits: ['changed-to-cod'],
    setup: () => ({ v$: useVuelidate() }),
    validations () {
      return {
        onlinePayment: {
          cardDescription: {
            model: {
              required: requiredIf(function () {
                return this.cardDataRequired;
              }),
            },
          },
          lastDigits: {
            model: {
              required: requiredIf(function () {
                return this.cardDataRequired;
              }),
              maxLength: maxLength(4),
              minLength: minLength(4),
            },
          },
        },
      };
    },
    data () {
      return {
        showPaymentForm: true,
        onlinePayment: {
          cardDescription: {
            model: '',
            type: 'text',
            name: 'cardDescription',
            label: this.$awt('checkout.payment.card_description'),
            labelAfter: this.$awt('aw.checkout.payment.card_description_example'),
            labelAttrs: {
              for: 'cardDescription',
            },
            widgetAttrs: {
              id: 'cardDescription',
            },
            version2: true,
          },
          lastDigits: {
            model: null,
            type: 'number',
            name: 'lastDigits',
            label: this.$awt('checkout.payment.card_last_four_digit'),
            labelAfter: this.$awt('aw.checkout.payment.card_last_four_digit_example'),
            labelAttrs: {
              for: 'lastDigits',
            },
            widgetAttrs: {
              id: 'lastDigits',
            },
            tokens: {
              maxLength: 'aw.checkout.paying_informations.card_last_four_digit_length_error',
              minLength: 'aw.checkout.paying_informations.card_last_four_digit_length_error',
            },
            version2: true,
          },
          keep: {
            model: false,
            type: 'checkbox',
            name: 'kepp',
            labelAttrs: {
              for: 'kepp',
            },
            widgetAttrs: {
              id: 'kepp',
            },
            widgetProps: {
              label: this.$awt('aw.checkout.paying_informations.keep_card'),
              showLabelInDesktop: true,
            },
            version2: true,
          },
          card: {
            model: '',
            options: [],
            dropdownIconAttrs: {
              name: 'arrow-down-16',
              size: 16,
            },
            multiple: false,
            type: 'select',
            name: 'card',
            labelAttrs: {
              for: 'card',
            },
            widgetAttrs: {
              id: 'card',
            },
            version2: true,
          },
        },
        paymentChangeable: false,
      };
    },
    computed: {
      cardDataRequired () {
        return this.showCardInfo && !this.onlinePayment.card.model?.value;
      },
    },
    async mounted () {
      let cards;
      try {
        cards = await this.$api.$get('/me/credit_cards');
        this.onlinePayment.card.options = (cards.length < CREDIT_CARD_MAX_COUNT_MAGIC_NUMBER
          ? [{
            name: this.$awt('checkout.payment.start_new_reg_transaction'),
            value: null,
          }]
          : []
        ).concat(cards.map(card => ({
          name: `${card.description} (${card.lastDigits})`,
          value: card.id,
        })));
        this.onlinePayment.card.model = this.onlinePayment.card.options[0] || '';

        const orderResults = await this.$api.$get(`/orders/${this.orderIdent}`);
        // NOTE: explicit false check for backward compatibility with API
        // https://git.loginet.hu/auchan-webshop/auchan-ecommerce/-/merge_requests/4041#note_128327
        this.paymentChangeable = orderResults.paymentChangeable !== false;
      } catch (error) {
        if (!(error?.response?.status === 404 || error?.response?.status === 403)) {
          this.$logger.error(error);
          await setErrorNotification({ nuxtApp: useNuxtApp(), error });
        }
      }
    },
    methods: {
      async onSubmitForm (orderIdent) {
        const orderStore = useOrderStore();
        // retry payment
        try {
          let cardData;
          if (this.cardDataRequired) {
            cardData = {
              description: this.onlinePayment.cardDescription.model,
              lastDigits: this.onlinePayment.lastDigits.model + '',
              keep: this.onlinePayment.keep.model,
            };
          } else {
            cardData = {
              id: this.onlinePayment.card.model.value,
            };
          }
          const config = useRuntimeConfig();
          const browserStats = new BrowserStats({
            config: config.public,
            $cookies: this.$cookies,
            $logger: this.$logger,
          }).addStats();
          const result = await this.$api.$post('/payment_retry', {
            orderIdent,
            cardData,
          });
          browserStats.removeStats();

          if (result.paymentResult.paymentStartedSuccessfully) {
            if (result.paymentResult.paymentRedirectNeeded) {
              window.open(result.paymentResult.paymentRedirectUrl, '_self');
            } else {
              const paymentData = await this.$api.$get(`/orders/${orderIdent}/payment_transactions`);
              orderStore.setPaymentData({
                paymentData,
                checkoutType: result?.orders[0]?.checkoutType,
              });
            }
          }
          this.showPaymentForm = false;
        } catch (error) {
          this.$logger.error(error);
          setErrorNotification({ nuxtApp: useNuxtApp(), error });
        }
      },
      async changeToCOD (orderIdent) {
        const orderStore = useOrderStore();
        // change payment method to CashOnDelivery
        try {
          await this.$api.post('/payment_change_to_cod', {
            orderIdent,
          });
          const notificationStore = useNotificationStore();
          notificationStore.pushSuccess({
            manualClose: false,
            text: {
              title: this.$awt('aw.basket.order_success.change_to_cod_success'),
            },
          });
          orderStore.setPaymentData({
            paymentData: null,
            checkoutType: null,
          });
          this.showPaymentForm = false;
          this.$emit('changed-to-cod', orderIdent);
        } catch (error) {
          this.$logger.error(error);
          setErrorNotification({ nuxtApp: useNuxtApp(), error });
        }
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.formFields {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
  @include desktop-medium(min) {
    flex-direction: row;
  }

  .card {
    grid-area: card;
    width: 200px;
    max-width: 300px;
  }

  .description {
    grid-area: description;
    width: 248px;
  }

  .lastDigits {
    grid-area: lastDigits;
    width: 200px;
  }

  .keep {
    grid-area: keep;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  @include desktop-medium(min) {
    flex-direction: row;
  }
}
</style>
